/* eslint-disable import/prefer-default-export */
/*
 * Собирает все middleware из маршрута и последовательно выполняет их
 */

const middlewarePipeline = ({
  to, from, next, store,
}) => {
  const middlewares = new Set(to.matched.flatMap((r) => (r.meta.middleware ? r.meta.middleware : [])));
  if (middlewares.size === 0) {
    return next();
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const middleware of middlewares) {
    const result = middleware({ store, from });
    if (result) {
      return next(result);
    }
  }
  return next();
};

export { middlewarePipeline };
