import { RouteConfig } from 'vue-router';
import * as Names from '@/plugins/vue-router/routeNames';

const routes: RouteConfig[] = [
  {
    path: 'divisions',
    name: Names.R_ADMIN_ORGSTRUCTUREV2_DIVISIONS,
    component: () => import('@/views/admin/orgstructure/divisions/OrgstructureDivisions.vue'),
  },
  {
    path: 'divisions/new',
    name: Names.R_ADMIN_ORGSTRUCTUREV2_DIVISIONS_NEW,
    component: () => import('@/views/admin/orgstructure/divisions/OrgstructureDivisionsNew.vue'),
  },
  {
    path: 'divisions/:divisionId/edit',
    name: Names.R_ADMIN_ORGSTRUCTUREV2_DIVISIONS_EDIT,
    component: () => import('@/views/admin/orgstructure/divisions/OrgstructureDivisionsEdit.vue'),
  },
];

export default routes;
