import { computed, ref } from 'vue';
import {
  isArray, isObject, merge,
} from 'lodash';
import Tree from '@/utils/tree/tree';
import Pager from '@/utils/tree/pager';
import { UUID } from '@/domains/common';

interface IFetchRequest {
  id?: string;
}

interface IFetchHandler {
  (params?: IFetchRequest): any; // TODO: описать интерфейс
}

interface ITreeInitParams {
  fetchHandler?: IFetchHandler;
  pageLimit?: number;
}

interface TreeItem {
  id: UUID;
}

interface IDropChildrenOptions {
  permanent: boolean
}

const defaultDropChildrenOptions: IDropChildrenOptions = {
  permanent: false,
};

const DEFAULT_OPTIONS = {
  fetchHandler: null,
  pageLimit: 2,
};

function useTree() {
  let options: ITreeInitParams = {};
  const tree = ref();

  const isLoading = ref<boolean>(false);
  const getTree = computed(() => tree.value);
  const getRootNodes = computed(() => tree.value.children);

  const addRootNodeToTree = (node: TreeItem) => {
    const newTree = new Tree(
      { ...node }, { pager: new Pager({ limit: options.pageLimit }) },
    );
    tree.value.addChild(newTree);
    return newTree;
  };

  // TODO: метод нужен, потому что сервер всегда возвращает массив, даже если запрашивается один конкретный элемент
  // когда ручку исправят, можно будет заменить этот метод на прямой вызов
  const getNode = (treeNode: any) => {
    // возвращаем первый элемент, если пришел массив
    if (isArray(treeNode)) {
      return treeNode[0];
    }
    if (isObject(treeNode)) {
      return treeNode;
    }
    return treeNode;
  };

  const sortByName = (a: any, b: any) => {
    const aName = a.name || a.displayName;
    const bName = b.name || b.displayName;
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  };

  const sortTreeByName = (a: any, b: any) => {
    const aName = a.model.name || a.model.displayName;
    const bName = b.model.name || b.model.displayName;
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  };

  const getParents = async (id: UUID) => {
    if (!options.fetchHandler) return;
    let parent = id;
    const result = [];
    while (parent) {
      // eslint-disable-next-line no-await-in-loop
      const response = await options.fetchHandler({ id: parent });
      const node = getNode(response);
      parent = node.parentId || null;
      result.push(node);
    }
    // eslint-disable-next-line consistent-return
    return result;
  };

  const fetchChildrenByPath = async (nodeId: UUID) => {
    if (!nodeId) return;
    if (!options.fetchHandler) return;
    const limit = options.pageLimit || 1;
    const isNodeInTree = tree.value.find(({ id }: TreeItem) => id === nodeId);
    if (!isNodeInTree) {
      // подтягиваем данные по текущей ноде
      const response = await options.fetchHandler({ id: nodeId });
      const currentNode = getNode(response);
      // получаем данные по всем родительским нодам
      const parents = await getParents(currentNode.parentId) || [];
      // к полученным данным добавляем текущую ноду
      const result = [...parents.reverse(), currentNode];
      // строим дерево, после получения данных
      result.forEach((node, index) => {
        const {
          children,
        } = node;
        let currentTree = tree.value.find(({ id }: TreeItem) => id === node.id);
        if (!currentTree && index === 0) {
          currentTree = addRootNodeToTree(node);
          // TODO: разобраться с сортировкой
          // currentTree.parent.children.sort(this.sortTreeByName);
        }
        if (!currentTree.model.hasChildren && (node.hasChildren || children.length)) {
          currentTree.model.hasChildren = true;
        }
        const isLast = index === result.length - 1;
        if (!isLast) {
          children.sort(sortByName);
          currentTree.pager.total = children.length;
          const childId = result[index + 1].id;
          const childPagePosition = Math.ceil((children.findIndex((i: any) => i.id === childId) + 1) / limit);
          if (childPagePosition > currentTree.pager.page) {
            currentTree.pager.page = childPagePosition;
          }
          const childrenList = children.slice(0, currentTree.pager.page * limit);
          childrenList.forEach((childTeam: any) => {
            const childNode = tree.value.find(({ id }: TreeItem) => id === childTeam.id);
            if (!childNode) {
              const newTree = new Tree(childTeam, { pager: new Pager({ limit }) });
              currentTree.addChild(newTree);
            }
          });
        }
      });
    }
  };

  const dropChildren = (id: string, { permanent }: IDropChildrenOptions = defaultDropChildrenOptions) => {
    if (Array.isArray(id)) {
      id.map((i) => tree.value.delete((item: TreeItem) => item.id === i));
    } else {
      let targetParent = null;

      const targetNode = tree.value.find(({ id: findId }: TreeItem) => findId === id);
      if (targetNode.parent) targetParent = targetNode.parent;

      tree.value.delete((item: any) => item.id === id);
      // Раскоментировать когда поправится на бэке баг с перепривязкой
      if (permanent && targetParent && /*! targetNode.model.hasChildren && */ !targetParent.children.length) {
        targetParent.model.hasChildren = false;
      }
    }
  };

  // сбросить все открытые панели, открытой может быть только одна
  const dropAllOnLevel = (children: any) => {
    children.forEach((child: any) => {
      if (child.children.length > 0) {
        const childrenIds = child.children.map((item: any) => item.model.id);
        dropChildren(childrenIds);
      }
    });
  };

  const fetchChildren = async (nodeId: string, drop?: boolean) => {
    // if (this.opened !== nodeId) {
    //   this.$emit('update:opened', nodeId);
    // }

    if (!options.fetchHandler) return;

    const currentTree = tree.value.find((item: any) => item.id === nodeId);
    if (!currentTree) return;

    // закрываем все открытые ветки
    // TODO: метод оказывает эффект на пагинацию, нужно исследовать
    if (drop) {
      dropAllOnLevel(currentTree.parent.children);
    }

    if (currentTree.children.length <= currentTree.pager.limit) {
      currentTree.pager.page = 1;
    }

    if (currentTree.children.length === 0) {
      currentTree.pager.page = 0;
    }

    const { limit, page } = currentTree.pager;

    try {
      currentTree.pager.loading = true;
      const response = await options.fetchHandler({ id: nodeId });
      const { children } = getNode(response);
      // children.sort(this.sortByName);
      const result = children.slice(page * limit, page * limit + limit);
      result.forEach((node: any) => {
        const newTree = new Tree(node, { pager: new Pager({ limit: options.pageLimit }) });
        currentTree.addChild(newTree);
      });
      currentTree.pager.total = children.length;
      currentTree.pager.page = page + 1;
    } catch (error) {
      console.error(error);
    } finally {
      currentTree.pager.loading = false;
    }
  };

  const initParams = (params: ITreeInitParams) => {
    options = merge({}, DEFAULT_OPTIONS, params);
  };

  // TODO: добавить тип для промиса
  const init = async ({ fetchHandler, ...other } : ITreeInitParams) => {
    isLoading.value = true;
    try {
      initParams({
        fetchHandler,
        ...other,
      });
      if (!options.fetchHandler) {
        throw new Error('fetchHandler method is not implemented');
      }
      const result = await options.fetchHandler();
      isLoading.value = false;
      // инициализируем дерево, устанавливаем корневой элемент
      tree.value = new Tree(
        { id: 0 },
        { pager: new Pager({ limit: result.length, total: result.length, page: 1 }) },
      );
      if (!result.length) return;
      // добавляем "видимые" корневые ноды, первый уровень данных
      result.forEach((node: any) => addRootNodeToTree(node));
      // сразу подтягиваем первый уровень дочерних элементов у первой ноды
      await fetchChildren(result[0].id);
    } catch (error) {
      isLoading.value = false;
      console.error(error);
    }
  };

  return {
    init,
    isLoading,
    tree: getTree,
    getRootNodes,
    fetchChildren,
    fetchChildrenByPath,
    dropChildren,
    sortTreeByName,
  };
}

export { useTree };
