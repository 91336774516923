import dayjs from '@/plugins/dayjs';

import { useI18n } from 'vue-i18n-bridge';

const DATE_FORMAT = 'DD.MM.YYYY';

function useDate() {
  const { t } = useI18n();

  /**
   * Возвращает разницу между датой в прошлом и текущим днем в относительном формате
   *
   * Пример: Меньше месяца, 8 мес, 1 год и 3 мес, 3 года, 6 лет и 4 мес
   *
   * @param {string} startDate - дата в формате ДД.ММ.ГГГГ
   */
  const getRelativeDiff = (startDate = '') => {
    if (!startDate) return '';

    const now = dayjs();
    const date = dayjs(startDate, DATE_FORMAT);

    const diffYears = now.diff(date, 'year');
    const diffMonths = now.diff(date, 'month') % 12;
    const diffDays = now.diff(date, 'day');

    const formattedDiff = [];

    if (diffYears > 0) {
      formattedDiff.push(t('year', diffYears));
    }

    if (diffMonths > 0) {
      if (diffYears > 0) {
        formattedDiff.push(t('and'));
      }
      formattedDiff.push(t('month', diffMonths));
    }

    if (diffYears === 0 && diffMonths === 0 && diffDays > 0) {
      formattedDiff.push(t('month', 0));
    }

    if (formattedDiff.length > 0) {
      return formattedDiff.join(' ');
    }

    return '';
  };

  return {
    getRelativeDiff,
  };
}

export { useDate };
