import * as Names from '@/plugins/vue-router/routeNames';
import bottle from '@/plugins/di';

const hasFeatureFlag = (featureName: string) => () => {
  const { flags } = bottle.container.ff;

  if (flags[featureName] === false) {
    return { name: Names.R_ERROR_404 };
  }

  return false;
};

// eslint-disable-next-line import/prefer-default-export
export { hasFeatureFlag };
