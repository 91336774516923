import { computed, inject } from 'vue';
import { useRouter } from 'vue-router/composables';

import { RawLocation } from 'vue-router';

function useGoBack() {
  const routeNames = inject<Record<string, string>>('Names');

  const hasPreviousRoute = computed(() => window.history.state.hasPreview);

  const router = useRouter();

  function goBackHandler(defaultRoute: RawLocation | null = null) {
    if (hasPreviousRoute.value) return router.go(-1);
    if (!hasPreviousRoute.value && defaultRoute) return router.push(defaultRoute);
    return router.push({ name: routeNames?.R_ADMIN });
  }

  return {
    hasPreviousRoute,
    goBackHandler,
  };
}

export { useGoBack };
