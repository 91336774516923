import { RouteConfig } from 'vue-router';
import * as Names from '@/plugins/vue-router/routeNames';

const routes: RouteConfig[] = [
  {
    path: 'teams',
    name: Names.R_ADMIN_ORGSTRUCTUREV2_TEAMS,
    component: () => import('@/views/admin/orgstructure/teams/OrgstructureTeams.vue'),
  },
  {
    path: 'teams/new',
    name: Names.R_ADMIN_ORGSTRUCTUREV2_TEAMS_NEW,
    component: () => import('@/views/admin/orgstructure/teams/OrgstructureTeamsNew.vue'),
  },
  {
    path: 'teams/:teamId/edit',
    name: Names.R_ADMIN_ORGSTRUCTUREV2_TEAMS_EDIT,
    component: () => import('@/views/admin/orgstructure/teams/OrgstructureTeamsEdit.vue'),
  },
];

export default routes;
