import ru from '@/locales/ru.json';

export const getI18nLocale = (locale) => {
  const localeArray = locale.split('.');
  let result = null;

  localeArray.forEach((l, index) => {
    result = index === 0 ? ru[l] : result[l];
  });
  return result;
};
