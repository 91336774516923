export const R_LANDING_PAGE = 'landing';
export const R_ADMIN = 'app.admin';
export const R_ADMIN_USERS = 'app.admin.users';
export const R_ADMIN_LICENSES = 'app.admin.licenses';
export const R_ADMIN_APPLICATIONS = 'app.admin.applications';
export const R_ADMIN_APPLICATIONS_CREATE = 'app.admin.applications.create';
export const R_ADMIN_APPLICATIONS_EDIT = 'app.admin.applications.edit';
export const R_ADMIN_USERS_CREATE = 'app.admin.users.create';
export const R_ADMIN_USERS_EDIT = 'app.admin.users.edit';
export const R_ADMIN_ORGSTRUCTURE = 'app.admin.orgstructure';
export const R_ADMIN_ORGSTRUCTURE_TEAM = 'app.admin.orgstructure.team';
export const R_ERROR_401 = '401';
export const R_ERROR_403 = '403';
export const R_ERROR_404 = '404';
export const R_ERROR_500 = '500';

export const R_ADMIN_ORGSTRUCTURE_DATA = 'app.admin.orgstructure.data';
export const R_ADMIN_ORGSTRUCTURE_IMPORT_INDEX = 'app.admin.orgstructure.import.index';
export const R_ADMIN_ORGSTRUCTURE_IMPORT_UPLOAD = 'app.admin.orgstructure.import.upload';
export const R_ADMIN_ORGSTRUCTURE_IMPORT_PROCESSING = 'app.admin.orgstructure.import.processing';
export const R_ADMIN_ORGSTRUCTURE_EXPORT_INDEX = 'app.admin.orgstructure.export.index';

export const R_ADMIN_STAFF_POSITION = 'app.admin.staff-position';
export const R_ADMIN_STAFF_POSITION_CREATE = 'app.admin.staff-position.create';
export const R_ADMIN_STAFF_POSITION_EDIT = 'app.admin.staff-position.edit';

// Orgstructure v2
export const R_ADMIN_ORGSTRUCTUREV2 = 'app.admin.orgstructurev2';
export const R_ADMIN_ORGSTRUCTUREV2_DEACTIVATED = 'app.admin.orgstructurev2.deactivated';
export const R_ADMIN_ORGSTRUCTUREV2_POSITIONS = 'app.admin.orgstructurev2.positions';
export const R_ADMIN_ORGSTRUCTUREV2_TEAMS = 'app.admin.orgstructurev2.teams';
export const R_ADMIN_ORGSTRUCTUREV2_TEAMS_VIEW = 'app.admin.orgstructurev2.teams.view';
export const R_ADMIN_ORGSTRUCTUREV2_TEAMS_NEW = 'app.admin.orgstructurev2.teams.new';
export const R_ADMIN_ORGSTRUCTUREV2_TEAMS_EDIT = 'app.admin.orgstructurev2.teams.edit';
export const R_ADMIN_ORGSTRUCTUREV2_POSITIONS_NEW = 'app.admin.orgstructurev2.positions.new';
export const R_ADMIN_ORGSTRUCTUREV2_POSITION = 'app.admin.orgstructurev2.positions.new';
export const R_ADMIN_ORGSTRUCTUREV2_POSITION_EDIT = 'app.admin.orgstructurev2.positions.edit';
export const R_ADMIN_ORGSTRUCTUREV2_POSITION_VIEW = 'app.admin.orgstructurev2.positions.view';
export const R_ADMIN_ORGSTRUCTUREV2_DIVISIONS_NEW = 'app.admin.orgstructurev2.divisions.new';
export const R_ADMIN_ORGSTRUCTUREV2_DIVISIONS = 'app.admin.orgstructurev2.divisions';
export const R_ADMIN_ORGSTRUCTUREV2_DIVISIONS_EDIT = 'app.admin.orgstructurev2.divisions.edit';
export const R_ADMIN_ORGSTRUCTUREV2_DIVISIONS_VIEW = 'app.admin.orgstructurev2.divisions.view';

// /Orgstructure v2

// Users v2

export const R_ADMIN_USERSV2 = 'app.admin.usersv2';
export const R_ADMIN_USERSV2_NEW = 'app.admin.usersv2.new';
export const R_ADMIN_USERSV2_EDIT = 'app.admin.usersv2.edit';

// License v2

export const R_ADMIN_LICENSESV2 = 'app.admin.licensesv2';
export const R_ADMIN_LICENSEV2_VIEW = 'app.admin.licensev2.edit';
