import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/es5/locale/ru';
import TTLightTheme from '@uikit/themes/tt.light.theme';
import TTDarkTheme from '@uikit/themes/tt.dark.theme';
import icons from '@uikit/icons';
import projectColors from './colors';
import projectIcons from './icons';

Vue.use(Vuetify);

const options = {
  theme: {
    default: 'light',
    themes: {
      light: {
        ...TTLightTheme,
        ...projectColors,
      },
      dark: TTDarkTheme,
    },
    options: {
      // Note: IE does not support CSS Custom Properties
      customProperties: false,
    },
  },

  icons: {
    iconfont: icons.iconfont,
    values: {
      ...icons.values,
      ...projectIcons,
    },
  },

  lang: {
    locales: { ru },
    current: 'ru',
  },
};

export default new Vuetify(options);
