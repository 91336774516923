import { ImageSize } from '@/helpers/gogha/types';
import { UUID } from '../domains/common/index';
import { getImageUrl } from './gogha/getImageUrl';

export const avatarText = (firstName:string, lastName:string):string | null => {
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  }

  return null;
};

export const avatarUrl = (uuid: UUID, size?: ImageSize | null): string | null => {
  const type = 'user';
  const name = 'avatar';
  return getImageUrl({
    uuid, type, name, size,
  });
};

export const fio = (lastName:string, firstName:string, middleName:string) => [lastName, firstName, middleName]
  .filter((n) => n).join(' ');

export default {
  avatarText,
  avatarUrl,
  fio,
};
