import { RouteConfig } from 'vue-router';
import * as Names from '@/plugins/vue-router/routeNames';

import staffPositionsRoutes from './positions';
import teamsRoutes from './teams';
import divisionsRoutes from './divisions';

const routes: RouteConfig[] = [
  {
    path: 'orgstructure-v2',
    name: Names.R_ADMIN_ORGSTRUCTUREV2,
    redirect: {
      name: Names.R_ADMIN_ORGSTRUCTUREV2_DIVISIONS,
    },
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/orgstructure/OrgstructureRoot.vue'),
    },
    children: [
      ...staffPositionsRoutes,
      ...teamsRoutes,
      ...divisionsRoutes,
      {
        path: 'deactivated',
        name: Names.R_ADMIN_ORGSTRUCTUREV2_DEACTIVATED,
        component: () => import('@/views/admin/orgstructure/OrgstructureDeactivated.vue'),
      },
    ],
  },
  {
    path: 'orgstructure-v2/positions/:positionId?/view',
    name: Names.R_ADMIN_ORGSTRUCTUREV2_POSITION_VIEW,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import('@/views/admin/orgstructure/positions/OrgstructurePositionsView.vue'),
    },
  },
  {
    path: 'orgstructure-v2/teams/:teamId/view',
    name: Names.R_ADMIN_ORGSTRUCTUREV2_TEAMS_VIEW,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import('@/views/admin/orgstructure/teams/OrgstructureTeamsView.vue'),
    },
  },
  {
    path: 'orgstructure-v2/divisions/:divisionId/view',
    name: Names.R_ADMIN_ORGSTRUCTUREV2_DIVISIONS_VIEW,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import('@/views/admin/orgstructure/divisions/OrgstructureDivisionsView.vue'),
    },
  },
];

export default routes;
