import { RouteConfig } from 'vue-router';
import * as Names from '@/plugins/vue-router/routeNames';
import { CreateElement } from 'vue';

const routes: RouteConfig[] = [
  {
    path: 'users-v2',
    redirect: {
      name: Names.R_ADMIN_USERSV2,
    },
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: { render: (c:CreateElement) => c('router-view') },
    },
    children: [
      {
        path: '',
        name: Names.R_ADMIN_USERSV2,
        component: () => import('@/views/admin/users/UsersList.vue'),
      },
      {
        path: 'new',
        name: Names.R_ADMIN_USERSV2_NEW,
        component: () => import('@/views/admin/users/UsersNew.vue'),
      },
      {
        path: ':userId/edit',
        name: Names.R_ADMIN_USERSV2_EDIT,
        component: () => import('@/views/admin/users/UsersEdit.vue'),
      },
    ],
  },
];

export default routes;
