import { ref, readonly } from 'vue';

function useToggle(initialValue: boolean = false) {
  const state = ref<boolean>(initialValue);

  const toggle = () => {
    state.value = !state.value;
  };

  const enable = () => {
    state.value = true;
  };

  const disable = () => {
    state.value = false;
  };

  return {
    state: readonly(state),
    toggle,
    enable,
    disable,
  };
}

export { useToggle };
