import { useNotify, useI18n } from '@/composables';
import { capitalize } from 'lodash';

export function useCustomErrorNotify() {
  const { notify } = useNotify();
  const { t } = useI18n();

  const customErrorNotify = (e:any, defaultMessage: string = 'error') => {
    const errorText = e?.response?.data?.error?.message || t(defaultMessage);
    return notify.snackError(capitalize(errorText));
  };

  return {
    customErrorNotify,
  };
}
