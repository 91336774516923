import store from '@/plugins/vuex';
import { initProfile } from '@/plugins/vuex/actionTypes';
import { toggleSnack, setUserRoles, setUserAvatarUrl } from '@/plugins/vuex/mutationTypes';

export const snackSuccess = (message) => store.commit(toggleSnack, {
  color: 'success',
  message,
}, { root: true });

export const snackError = (message) => store.commit(toggleSnack, {
  color: 'error',
  message,
}, { root: true });

export const setRoles = (commit, roles) => commit(setUserRoles, roles, { root: true });

export const init = (action, payload) => action(initProfile, payload, { root: true });

export const setUserAvatar = (commit, payload) => commit(setUserAvatarUrl, payload, { root: true });
