import { avatarSizes } from '@/constants';
import { UUID } from '../../domains/common/index';
import { ImageSize, ImageType } from './types';
/* eslint-disable import/prefer-default-export */
const { VUE_APP_GOGHA_API_ADDRESS: baseUrl = 'http://localhost:3000/' } = process.env;

interface IImageAttrs {
  uuid: UUID,
  type: ImageType,
  name: string,
  size?: ImageSize | null
}

function getImageUrl({
  uuid, type, name, size,
}: IImageAttrs) {
  let url = `${baseUrl}${type}${uuid ? `/${uuid}` : ''}/${name}`;

  if (size) {
    url += `/${size}`;
  } else {
    url += `/${avatarSizes.xSmall}`;
  }

  return url;
}

export { getImageUrl };
