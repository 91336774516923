import * as Names from '@/plugins/vue-router/routeNames';
import isAdmin from '@/plugins/vue-router/guards/middleware/isAdmin';
import orgstructure from './orgstructure';
import users from './users';
import license from './license';

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/app/HolyGrailLayout.vue'),
    meta: {
      middleware: [
        isAdmin,
      ],
    },
    children: [
      {
        path: '',
        name: Names.R_LANDING_PAGE,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/LandingPage.vue'),
        },
      },
      {
        path: 'errors/401',
        name: Names.R_ERROR_401,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error401.vue'),
      },
      {
        path: 'errors/403',
        name: Names.R_ERROR_403,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error403.vue'),
      },
      {
        path: 'errors/404',
        name: Names.R_ERROR_404,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error404.vue'),
        },
      },
      {
        path: 'errors/500',
        name: Names.R_ERROR_500,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error500.vue'),
      },
    ],
  },
  {
    path: '/app/orgstructure/teams',
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/app/TreeLayout.vue'),
    meta: {
      middleware: [
        isAdmin,
      ],
    },
    redirect: { name: Names.R_ADMIN_ORGSTRUCTURE },
    children: [
      {
        path: '',
        name: Names.R_ADMIN_ORGSTRUCTURE,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/TeamTree.vue'),
        },
      },
    ],
  },
  {
    path: '/app',
    name: Names.R_ADMIN,
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/app/HolyGrailLayout.vue'),
    meta: {
      middleware: [
        isAdmin,
      ],
    },
    redirect: { name: Names.R_ADMIN_USERS },
    children: [
      {
        path: 'users',
        name: Names.R_ADMIN_USERS,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/Users.vue'),
        },
      },
      {
        path: 'users/:userId/edit',
        name: Names.R_ADMIN_USERS_EDIT,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/UserEdit.vue'),
        },
      },
      {
        path: 'users/create',
        name: Names.R_ADMIN_USERS_CREATE,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/UserCreate.vue'),
        },
      },
      {
        path: 'orgstructure/:companyId/team/:teamId',
        name: Names.R_ADMIN_ORGSTRUCTURE_TEAM,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/OrgstructureTeam.vue'),
        },
      },
      {
        path: 'orgstructure/:companyId/team/:teamId/staff-position/create',
        name: Names.R_ADMIN_STAFF_POSITION_CREATE,
        props: {
          default: true,
        },
        components: {
          // eslint-disable-next-line
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/staffPosition/StaffPositionCreate.vue'),
        },
      },
      {
        path: 'orgstructure/:companyId/team/:teamId/staff-position/:staffPositionId',
        name: Names.R_ADMIN_STAFF_POSITION,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/staffPosition/StaffPosition.vue'),
        },
      },
      {
        path: 'orgstructure/:companyId/team/:teamId/staff-position/:staffPositionId/edit',
        name: Names.R_ADMIN_STAFF_POSITION_EDIT,
        props: {
          default: true,
        },
        components: {
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/staffPosition/StaffPositionEdit.vue'),
        },
      },
      {
        path: 'orgstructure/data',
        name: Names.R_ADMIN_ORGSTRUCTURE_DATA,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/OrgstructureData.vue'),
        },
        redirect: { name: Names.R_ADMIN_ORGSTRUCTURE_IMPORT_INDEX },
        children: [
          {
            path: 'import',
            name: Names.R_ADMIN_ORGSTRUCTURE_IMPORT_INDEX,
            props: {
              default: true,
            },
            components: {
              nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
              'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
              default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/import/ImportIndex.vue'),
            },
          },
          {
            path: 'export',
            name: Names.R_ADMIN_ORGSTRUCTURE_EXPORT_INDEX,
            props: {
              default: true,
            },
            components: {
              nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
              'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
              default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/export/ExportIndex.vue'),
            },
          },
        ],
      },
      {
        path: '/orgstructure/data/import/:importId/upload',
        name: Names.R_ADMIN_ORGSTRUCTURE_IMPORT_UPLOAD,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/import/ImportUpload.vue'),
        },
      },
      {
        path: 'orgstructure/data/import/:importId/processing',
        name: Names.R_ADMIN_ORGSTRUCTURE_IMPORT_PROCESSING,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/import/ImportProcessing.vue'),
        },
      },
      {
        path: 'licenses',
        name: Names.R_ADMIN_LICENSES,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/Licenses.vue'),
        },
      },
      {
        path: 'applications',
        name: Names.R_ADMIN_APPLICATIONS,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/Applications.vue'),
        },
      },
      {
        path: 'applications/create',
        name: Names.R_ADMIN_APPLICATIONS_CREATE,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/ApplicationsCreate.vue'),
        },
      },
      {
        path: 'applications/:appId',
        name: Names.R_ADMIN_APPLICATIONS_EDIT,
        props: {
          default: true,
        },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/admin/ApplicationsEdit.vue'),
        },
      },
      ...orgstructure,
      ...users,
      ...license,
    ],
  },
  {
    path: '*',
    redirect: { name: Names.R_ERROR_404 },
  },
];

export default routes;
