export default class Tree<T> {
  model: T;

  parent?: Tree<T>;

  children = [] as Array<Tree<T>>;

  [key: string]: any;

  constructor(model: T, options?: any) {
    this.model = model;
    Object.assign(this, options);
  }

  update(model: Partial<T>): T {
    this.model = { ...this.model, ...model };
    return this.model;
  }

  addChild(child: Tree<T>, index?: number): Tree<T> {
    // eslint-disable-next-line no-param-reassign
    child.parent = this;
    if (Number.isFinite(index)) {
      this.children = [...this.children.slice(0, index), child, ...this.children.slice(index)];
    } else this.children.push(child);
    return child;
  }

  drop() {
    if (this.parent) {
      this.parent.children = this.parent.children.filter((child) => child !== this);
      this.parent = undefined;
    }
    this.children = [];
  }

  delete(comparisonFn: (model: T) => boolean) {
    const deletedChild = this.find(comparisonFn);
    deletedChild?.drop();
  }

  find(comparisonFn: (model: T) => boolean): Tree<T> | undefined {
    const recurseFind = (fn: (model: T) => boolean, tree: Tree<T>): Tree<T> | undefined => {
      if (fn(tree.model)) return tree;
      let result: Tree<T> | undefined;
      tree.children.forEach((t) => {
        const res = recurseFind(fn, t);
        if (res) result = res;
      });
      return result;
    };

    return recurseFind(comparisonFn, this);
  }
}
