import { RouteConfig } from 'vue-router';
import * as Names from '@/plugins/vue-router/routeNames';

const routes: RouteConfig[] = [
  {
    path: 'positions',
    name: Names.R_ADMIN_ORGSTRUCTUREV2_POSITIONS,
    component: () => import('@/views/admin/orgstructure/positions/OrgstructurePositions.vue'),
  },
  {
    path: 'positions/new',
    name: Names.R_ADMIN_ORGSTRUCTUREV2_POSITIONS_NEW,
    component: () => import('@/views/admin/orgstructure/positions/OrgstructurePositionsNew.vue'),
  },

  {
    path: 'positions/:positionId?/edit',
    name: Names.R_ADMIN_ORGSTRUCTUREV2_POSITION_EDIT,
    component: () => import('@/views/admin/orgstructure/positions/OrgstructurePositionsEdit.vue'),
  },
];

export default routes;
