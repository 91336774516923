import { IRequestPagination, UUID } from '@/domains/common';
/* eslint-disable import/prefer-default-export */
import * as methods from '@/services/api/constants';
import transport from './transport';
import {
  IGetTeamByIdResponse,
  ICreateTeamRequest,
  ICreateTeamResponse,
  IGetTeamHierarchyRequest,
  IGetTeamHierarchyResponse,
  IGgetTeamUsersResponse,
  ITeamSearchResponse,
  ITeamSearchRequest,
  IGetTeamsResponse, IUpdateTeamResponse,
} from './types';

/**
 * Гайд по именованию ресурсов
 *
 * @see: https://burning-heart.atlassian.net/wiki/spaces/front/pages/1375109156/JavaScript
 */

export const getTeams = (options = {}) => transport({
  url: 'admin/teams',
  method: methods.HTTP_GET,
  ...options,
});

export const getTeam = ({ teamId, ...options } : { teamId: UUID }) => transport({
  url: `admin/teams/${teamId}`,
  method: methods.HTTP_GET,
  ...options,
});

export const updateTeam = ({ teamId, data, ...options } : { teamId: UUID, data: any }) => transport({
  url: `admin/teams/${teamId}`,
  method: methods.HTTP_PUT,
  ...options,
  data,
});

export const deleteTeam = ({ teamId, ...options } : { teamId: UUID }) => transport({
  url: `admin/teams/${teamId}`,
  method: methods.HTTP_DELETE,
  ...options,
});

export const getTeamStaffPositions = ({ teamId, ...options } : { teamId: UUID }) => transport({
  url: `admin/teams/${teamId}/staff_positions`,
  method: methods.HTTP_GET,
  ...options,
});

export const getTeamExtendedStaffPositions = ({ teamId, ...options }:
  { teamId: UUID }) => transport({
  url: `admin/teams/${teamId}/extended_staff_positions`,
  method: methods.HTTP_GET,
  ...options,
});

export const createTeam = ({ data, ...options } : { data: any }) => transport({
  url: 'admin/teams',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const createTeamLink = ({ data, ...options } : { data: any }) => transport({
  url: 'admin/teams/link',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const checkTeamLink = ({ data, ...options } : { data: any }) => transport({
  url: 'admin/teams/check_link',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const deleteTeamLink = ({ data, ...options } : { data: any }) => transport({
  url: `admin/teams/link/${data.parentId}/${data.childId}`,
  method: methods.HTTP_DELETE,
  data,
  ...options,
});

export const searchTeams = ({ data = {}, ...options } = {}) => transport({
  url: '/admin/teams/search',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const searchV3Teams = (data: ITeamSearchRequest): Promise<ITeamSearchResponse> => transport({
  url: '/admin/v3/teams/search',
  method: methods.HTTP_POST,
  data,
});

export const createTeamAndLink = ({ data, ...options }: { data: any }) => transport({
  url: '/admin/teams/create_and_link',
  method: methods.HTTP_POST,
  data,
  ...options,
});

/*
 * Команды пользователя
 */
export const profileGetTeams = ({ data = {}, ...options } = {}) => transport({
  url: 'profile/teams_with_people',
  method: methods.HTTP_GET,
  data,
  ...options,
});

// V3 ручки

export const getV3TeamHierarchy = ({ id }:IGetTeamHierarchyRequest): Promise<IGetTeamHierarchyResponse> => transport({
  url: `/admin/v3/teams/hierarchy?id=${id}`,
  method: methods.HTTP_GET,
});

export const getV3TeamInfo = (): Promise<IGetTeamsResponse> => transport({
  url: '/admin/v3/teams',
  method: methods.HTTP_GET,
});

export const getV3TeamById = (id: UUID): Promise<IGetTeamByIdResponse> => transport({
  url: `/admin/v3/teams/${id}`,
  method: methods.HTTP_GET,
});

export const createV3Team = (data: ICreateTeamRequest): Promise<ICreateTeamResponse> => transport({
  url: '/admin/v3/teams',
  method: methods.HTTP_POST,
  data,
});

export const updateV3Team = (id: UUID, data: ICreateTeamRequest): Promise<IUpdateTeamResponse> => transport({
  url: `/admin/v3/teams/${id}`,
  method: methods.HTTP_PUT,
  data,
});

export const deleteV3Team = (id: UUID): Promise<{}> => transport({
  url: `admin/v3/teams/${id}`,
  method: methods.HTTP_DELETE,
});

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 100,
};

export const getV3TeamUsersById = (id: UUID, isLeader: boolean = false,
  pagination:IRequestPagination = DEFAULT_PAGINATION): IGgetTeamUsersResponse => transport({
  url: `/admin/v3/teams/${id}/users`,
  method: methods.HTTP_GET,
  params: {
    isLeader,
    pagination,
  },
});
