export const toggleSnack = 'toggleSnack';
export const toggleLoading = 'toggleLoading';
export const toggleNavDrawer = 'toggleNavDrawer';
export const setUserRoles = 'setUserRoles';
export const setAdmin = 'setAdmin';
export const setChiefs = 'setChiefs';
export const setTeams = 'setTeams';
export const setApps = 'setApps';
export const setUserAvatarUrl = 'setUserAvatarUrl';
export const setPreviousRoute = 'setPreviousRoute';
