/* eslint-disable import/prefer-default-export */
import transport from '@/services/api/features/transport/index';
import * as methods from '@/services/api/constants';

/**
 * Гайд по именованию ресурсов
 * https://burning-heart.atlassian.net/wiki/spaces/front/pages/1375109156/JavaScript
 *
 */

export const featuresGet = (data?: any, ...options: any) => transport({
  url: '/features',
  method: methods.HTTP_GET,
  headers: {
    'UNLEASH-INSTANCEID': process.env.VUE_APP_FF_INSTANCEID,
    'UNLEASH-APPNAME': process.env.VUE_APP_MODE,
  },
  params: data,
  ...options,
});
