/*
 * Гвард для защиты админских маршрутов
 */
const { VUE_APP_ARCHIVIST_ADDRESS } = process.env;

const isAdmin = ({ store }) => {
  if (!store.state.isAdmin) {
    window.location.replace(VUE_APP_ARCHIVIST_ADDRESS);
  }
  return false;
};

export default isAdmin;
