import { NavigationGuardNext, Route } from 'vue-router';
/* eslint-disable import/prefer-default-export */
import { companyId } from '@/services/cookies';
import { getDomainForCookies } from '@/helpers/url';

const { VUE_APP_GATEKEEPER_ADDRESS } = process.env;

/*
 * Обязательный guard для выполнения перед всеми переходами
 */
function hasCompany(to: Route, from: Route, next: NavigationGuardNext) {
  const id = companyId.get();

  if (!id) {
    companyId.remove({ domain: getDomainForCookies() });

    // IMPORTANT: редиректим в гейткипер с сылкой на текущую страницу
    const url = new URL(`/?back=${window.location.href}`, VUE_APP_GATEKEEPER_ADDRESS);
    window.location.replace(url.href);

    return next(false);
  }

  return next();
}

export { hasCompany };
