import Router, { RouterOptions } from 'vue-router';
import store from '@/plugins/vuex';
import { hasToken } from '@/plugins/vue-router/guards/hasToken';
import { hasCompany } from '@/plugins/vue-router/guards/hasCompany';
import { isMobileOrTablet } from '@/plugins/vue-router/guards/isMobile';
import { middlewarePipeline } from '@/plugins/vue-router/guards/middleware/middlewarePipeline';
import previousRoute from './guards/middleware/previousRoute';

function createRouter({ routes, base, ...options }: RouterOptions): Router {
  const router = new Router({
    mode: 'history',
    base,
    // Note: отображается во вкладке маршрутов в devtools@^5.0.1
    routes,
    ...options,
  });

  router.beforeEach(hasToken);
  router.beforeEach(hasCompany);
  router.beforeEach(isMobileOrTablet);
  router.beforeEach((to, from, next) => middlewarePipeline({
    to, from, next, store,
  }));
  router.afterEach((to, from) => previousRoute(to, from));

  return router;
}

export default createRouter;
