import Vue from 'vue';
import App from '@/App.vue';
import '@/directives';
import bottle from '@/plugins/di';
import * as profileApi from '@/services/api/profile';
import * as teamApi from '@/services/api/teams';
import { init } from '@/helpers/store';

import {
  i18n, router, store, vuetify, usedesk, setupDi, helpdeskeddy,
} from '@/plugins';
import { fio } from '@/helpers/users';

Vue.config.productionTip = false;

// TODO: подумать над инициализацией iife
(async () => {
  try {
    const profile = await profileApi.profileGet();
    const isAdmin = await profileApi.profileIsAdmin();
    const chiefs = await profileApi.profileGetChiefs();
    const { teamsWithPeople } = await teamApi.profileGetTeams();
    const apps = await profileApi.profileAppsGet();
    init(store.dispatch, {
      roles: profile.roles,
      isAdmin: isAdmin.response,
      chiefs,
      teams: teamsWithPeople,
      apps,
    });

    const { flags } = bottle.container.ff;
    if (flags.help_desk_eddy_enabled) {
      helpdeskeddy({
        userName: fio(profile.name.last, profile.name.first, profile.name.middle),
        userEmail: profile.email,
      });
    } else {
      usedesk(profile);
    }
  } catch (err) {
    // no-error
  } finally {
    setupDi(Vue);
    new Vue({
      name: 'TalentTech',
      i18n,
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
})();
