import Vue, { PluginFunction, VueConstructor } from 'vue';
import BottleJS from 'bottlejs';

import * as featuresApi from '@/services/api/features';
import FeatureFlag from '@/plugins/feature-flag';
import { snackError, snackSuccess } from '@/helpers/store';
import { useNotify, useCustomErrorNotify } from './composables';

const bottle = new BottleJS();

let flags: string[] = [];

bottle.defer(async () => {
  const { features } = await featuresApi.featuresGet();
  flags = features;
});

bottle.resolve('flags');

bottle.factory('ff', () => new FeatureFlag(flags));

bottle.factory('notify', () => ({
  snackError,
  snackSuccess,
}));

const install:PluginFunction<Vue> = (vue) => {
  Object.defineProperty(vue.prototype, '$di', {
    get() {
      return bottle.container;
    },
  });
};

export function setupDi(vue: VueConstructor, installFn?: PluginFunction<Vue>) {
  if (installFn != null) {
    vue.use(installFn);
  } else {
    vue.use(install);
  }
}

export default bottle;
export { useNotify, useCustomErrorNotify };
