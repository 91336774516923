import { RouteConfig } from 'vue-router';
import * as Names from '@/plugins/vue-router/routeNames';
import { CreateElement } from 'vue';
import { hasFeatureFlag } from '../../guards/middleware/hasFeatureFlag';

const routes: RouteConfig[] = [
  {
    path: 'license-v2',
    redirect: {
      name: Names.R_ADMIN_LICENSESV2,
    },
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: { render: (c:CreateElement) => c('router-view') },
    },
    children: [
      {
        path: '',
        name: Names.R_ADMIN_LICENSESV2,
        component: () => import('@/views/admin/license/LicenseList.vue'),
        beforeEnter: ((to, from, next) => {
          const redirect = hasFeatureFlag('admin_license_v2')();
          return redirect ? next(redirect) : next();
        }),
      },
      {
        path: ':licenseId/view',
        name: Names.R_ADMIN_LICENSEV2_VIEW,
        component: () => import('@/views/admin/license/LicenseView.vue'),
        beforeEnter: ((to, from, next) => {
          const redirect = hasFeatureFlag('admin_license_v2')();
          return redirect ? next(redirect) : next();
        }),
      },
    ],
  },
];

export default routes;
