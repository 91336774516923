/* @see: https://developer.mozilla.org/ru/docs/Web/HTTP/Methods */
export const HTTP_GET = 'GET';
export const HTTP_HEAD = 'HEAD';
export const HTTP_POST = 'POST';
export const HTTP_PUT = 'PUT';
export const HTTP_DELETE = 'DELETE';
export const HTTP_CONNECT = 'CONNECT';
export const HTTP_OPTIONS = 'OPTIONS';
export const HTTP_TRACE = 'TRACE';
export const HTTP_PATCH = 'PATCH';

export enum HttpStatus {
  CONTINUE = 100,
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  REQUEST_TIMEOUT = 408,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
}
