export default {
  // Пример использования
  // ratingFullStar: 'fas fa-star',
  arrowToBottom: 'fal fa-arrow-to-bottom',
  importFile: 'fal fa-file-import',
  plus: 'fal fa-plus',
  plusR: 'far fa-plus',
  plusCircle: 'fal fa-plus-circle',
  minusCircle: 'fal fa-minus-circle',
  arrowLeft: 'fal fa-arrow-left',
  ellipsis: 'fal fa-ellipsis-h',
  ellipsisV: 'fal fa-ellipsis-v',
  search: 'fal fa-search',
  sitemap: 'fal fa-sitemap',
  bars: 'fal fa-bars',
  times: 'fal fa-times',
  questionCircle: 'fal fa-question-circle',
  trash: 'fal fa-trash-alt',
  userPlus: 'fal fa-user-plus',
  angleDown: 'fal fa-angle-down',
  user: 'fal fa-user',
  ban: 'fal fa-ban',
  powerOff: 'fal fa-power-off',
  star: 'fa-regular fa-star',
  chevronLeft: 'fal fa-chevron-left',
};
