export const foo = 'bar';
export const APP_GOGHA_URL = process.env.VUE_APP_GOGHA_API_ADDRESS || 'http://localhost:3000/';
export const redirectUrl = {
  GATEKEEPER_ADDRESS: process.env.VUE_APP_GATEKEEPER_ADDRESS,
  MOBILE_AND_TABLE_REDIRECT_LINK: `${process.env.VUE_APP_GATEKEEPER_ADDRESS}not-supported`,
};

export const maxAvailableWindowWidthSize = 1265;

export const platformApps = {
  // TT ЦЕЛИ
  pms: process.env.VUE_APP_GOALS_ADDRESS,
  // ТТ Обучение
  skilldev: process.env.VUE_APP_LEARNING_ADDRESS,
  // Вовлеченность
  engagement: process.env.VUE_APP_ENGAGEMENTADMIN_ADDRESS,
};

export const LEARNING_APPS_LIST = [
  'learning-license',
  'assessment',
  'catalogue',
  'expert_assessment',
  'lxp',
  'lxp-assignment',
  'lxp-multi-level-test',
  'multi_content',
  'multi_level_test',
  'skilldev',
  'tracks',
  'tt_course',
];

export const avatarSizes = {
  xSmall: '69x69',
  small: '96x96',
};
