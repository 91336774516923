import { Store } from 'vuex';
import {
  toggleSnack,
  toggleLoading,
  toggleNavDrawer,
  setUserRoles,
  setAdmin,
  setChiefs,
  setTeams,
  setApps,
  setUserAvatarUrl,
  setPreviousRoute,
} from '@/plugins/vuex/mutationTypes';
import { initProfile } from '@/plugins/vuex/actionTypes';
import modules from '@/plugins/vuex/modules';
import plugins from '@/plugins/vuex/plugins';

const strict = process.env.NODE_ENV === 'development';

function createStore() {
  const store = new Store({
    strict,
    plugins,
    modules,

    // TODO: refactor use modules
    state: {
      loading: false,
      navDrawer: true,
      snackbar: {
        value: false,
        message: '',
        color: '',
        timeout: 2000,
      },
      isAdmin: false,
      roles: [],
      chiefs: [],
      teams: [],
      apps: [],
      userAvatarUrl: '',
      previousRoute: null,
    },

    actions: {
      [initProfile]({ commit }, payload) {
        const {
          roles,
          isAdmin,
          chiefs,
          teams,
          apps,
        } = payload;

        commit(setUserRoles, roles);
        commit(setAdmin, isAdmin);
        commit(setChiefs, chiefs);
        commit(setTeams, teams);
        commit(setApps, apps);
      },
    },

    mutations: {
      [toggleLoading](state, payload) {
        state.loading = payload;
      },

      [setUserRoles](state, payload) {
        state.roles = payload;
      },

      [setAdmin](state, payload) {
        state.isAdmin = payload;
      },

      [toggleNavDrawer](state, payload) {
        state.navDrawer = payload;
      },

      [toggleSnack](state, payload) {
        const { snackbar } = state;
        snackbar.value = !snackbar.value;
        snackbar.message = payload.message || '';
        snackbar.color = payload.color || '';
      },

      [setChiefs](state, payload) {
        state.chiefs = payload;
      },

      [setTeams](state, payload) {
        state.teams = payload;
      },

      [setApps](state, payload) {
        state.apps = payload || [];
      },

      [setUserAvatarUrl](state, payload) {
        state.userAvatarUrl = payload;
      },

      [setPreviousRoute](state, payload) {
        state.previousRoute = payload;
      },
    },

    getters: {},
  });

  return store;
}

export default createStore;
