/* eslint-disable import/prefer-default-export */
import transport from '@/services/api/profile/transport';
import * as methods from '@/services/api/constants';
import { UUID } from '../../../domains/common/index';

/**
 * Гайд по именованию ресурсов
 *
 * @see: https://burning-heart.atlassian.net/wiki/spaces/front/pages/1375109156/JavaScript
 */

/*
 * Сводная информация по профилю пользователя
 *
 * @example: profileGet();
 */
export const profileGet = ({ data = {}, ...options } = {}) => transport({
  url: 'profile',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const profileIsAdmin = ({ data = {}, ...options } = {}) => transport({
  url: '/is_admin',
  method: methods.HTTP_GET,
  data,
  ...options,
});

/*
 * Руководители пользователя
 */
export const profileGetChiefs = ({ data = {}, ...options } = {}) => transport({
  url: 'profile/chiefs',
  method: methods.HTTP_GET,
  data,
  ...options,
});

/*
 * Команды пользователя
 */
export const profileGetTeams = ({ data = {}, ...options } = {}) => transport({
  url: 'profile/teams_with_people',
  method: methods.HTTP_GET,
  data,
  ...options,
});

/*
 * Список компаний пользователя
 *
 * @example: profileCompaniesGet();
 */
export const profileCompaniesGet = ({ data = {}, ...options } = {}) => transport({
  url: 'profile/companies',
  method: methods.HTTP_GET,
  data,
  ...options,
});

/*
 * Возвращает список названий приложений, к которым у компании или аккаунта
 * есть лицензии
 *
 * @example: profileAppsGet();
 */
export const profileAppsGet = ({ data = {}, ...options } = {}) => transport({
  url: 'profile/apps',
  method: methods.HTTP_GET,
  data,
  ...options,
});

/*
 * Отправляет новый аватар из цифрового профиля
 *
 * @example: profileAvatarPost();
 * @example: profileAvatarPost({ data: formData });
 */
export const profileAvatarPost = ({ data = {}, ...options } = {}) => transport({
  url: 'profile/avatar',
  method: methods.HTTP_POST,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  data,
  ...options,
});

export const deleteAvatar = ({ ...options }) => transport({
  url: '/profile/avatar',
  method: methods.HTTP_DELETE,
  ...options,
});

export const adminLicensesGet = ({ data = {}, ...options } = {}) => transport({
  url: 'admin/licences',
  method: methods.HTTP_GET,
  ...options,
  data,
});

export const getScopes = ({ data = {}, ...options } = {}) => transport({
  url: '/scopes',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const getProfileScheme = ({ uuid, ...options } : {uuid: UUID}) => transport({
  url: `/v2/profile/${uuid}`,
  method: methods.HTTP_GET,
  ...options,
});

export const getProfileDS = ({ uuid, ds, ...options } : {uuid: UUID, ds: any}) => transport({
  url: `/v2/profile/${uuid}/ds/${ds}`,
  method: methods.HTTP_GET,
  ...options,
});
