import {
  ref, watch, onBeforeMount,
} from 'vue';
import { debounce } from '@/helpers';

type IFetchFn<T, P = void> = (arg: P) => Promise<T>

function useDebounce<T = unknown, P = void>(fetch: IFetchFn<T, P>, timeout: number = 250) {
  const search = ref<string>('');
  const debouncedFetch = ref();

  onBeforeMount(() => {
    debouncedFetch.value = debounce(fetch, timeout);
  });

  watch(search, (newSearch) => {
    debouncedFetch.value(newSearch);
  });
  return {
    search,
  };
}

export { useDebounce };
