interface IPager {
  total: number;
  page: number;
  limit: number;
  loading: boolean;
}

class Pager implements IPager {
  total: number;

  page: number;

  limit: number;

  loading: boolean;

  constructor(options?: Partial<Pager>) {
    this.page = options?.total || 0;
    this.total = options?.page || 0;
    this.limit = options?.limit || 0;
    this.loading = options?.loading || false;
  }
}

export default Pager;
